import React, { Component } from "react";
import { useLocation, Route, Switch, matchPath } from "react-router-dom";

import routes from "routes.js";

import AdminLayout from "layouts/Admin.js";
import BlankLayout from "layouts/Blank.js";

function Select(props) {
  const getRoute = (props) => {
    let route = routes.find(e => e.path === props.location.pathname);
    
    if(route === undefined) {
      return null;
    }
    else if(route.layout === "/admin") {
      return (
        <Route
          path={props.match.path}
          render={(props) => <AdminLayout {...props} />}
        />
      );
    }
    else if(route.layout === "/blank") {
      return (
        <Route
          path={props.match.path}
          render={(props) => <BlankLayout {...props} />}
        />
      );
    }
    else {
      return null;
    }
  };
  return (
    <>
      <Switch>{getRoute(props)}</Switch>
    </>
  );
}

export default Select;
