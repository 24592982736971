import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";
import "../assets/css/login.css";

function Login() {
  const {authen, setAuthen, setIsLogin} = useContext(AuthenContext);
  const [validated, setValidated] = useState(false);
  
  const [data, setData] = useState({
    "user": "",
    "password": ""
  });

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  const [loadingLogin, setLoadingLogin] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const loginSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingLogin(true);

      axios.post(process.env.REACT_APP_API_URL + "/admin/login", {
        "api_key": process.env.REACT_APP_API_KEY,
        "user": data.user,
        "password": data.password
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          sessionStorage.authen = JSON.stringify(data);
          setAuthen(data);
          setIsLogin(true);
          history.push(
            location.state === undefined || location.state.referrer.pathname === process.env.REACT_APP_ADMIN_PATH + "/logout"
            ? process.env.REACT_APP_ADMIN_PATH + "/school"
            : location.state.referrer.pathname
          );
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingLogin(false);
      });
    }
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center vh-100">
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="text-center">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={loginSubmit}>
                  <Form.Group>
                    <label>Username</label>
                    <Form.Control type="text" name="user" value={data.user} placeholder="Username" onChange={handleInputChange} required></Form.Control>
                    <Form.Control.Feedback type="invalid">กรุณาป้อน Username</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <label>Password</label>
                    <Form.Control type="password" name="password" value={data.password} placeholder="Password" onChange={handleInputChange} required></Form.Control>
                    <Form.Control.Feedback type="invalid">กรุณาป้อน Password</Form.Control.Feedback>
                  </Form.Group>
                  <ButtonLoading type="submit" icon="fas fa-key" className="btn btn-info btn-fill btn-block" loading={loadingLogin}>Login</ButtonLoading>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Login;
