import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/custom.css";

import SelectLayout from "./layouts/Select.js";
import AuthenContext from "./models/AuthenContext";
import Authen from "./authen";

function App() {
  const [authen, setAuthen] = useState(sessionStorage.authen === undefined ? undefined : JSON.parse(sessionStorage.authen));
  const [islogin, setIsLogin] = useState(!(sessionStorage.authen === undefined));
  const [renderRoute, setRenderRoute] = useState(null);

  useEffect(() => {
    if(renderRoute === null) return;

    if(sessionStorage.authen === undefined) {
      setIsLogin(false);
    }
    else {
      let authen = JSON.parse(sessionStorage.authen);
      Authen.getProfile(authen.token)
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          setIsLogin(true);
          setAuthen(JSON.parse(sessionStorage.authen));
        }
        else {
          sessionStorage.removeItem("authen");
          setIsLogin(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        //sessionStorage.removeItem("authen");
        setIsLogin(false);
      });
    }
  }, [islogin, renderRoute]);

  return (
    <AuthenContext.Provider value={{authen, setAuthen, setIsLogin}}>
      <BrowserRouter>
        <Switch>
          <Route path={process.env.REACT_APP_ADMIN_PATH + "/login"} render={(props) => <SelectLayout {...props} />} />
          <Route path={process.env.REACT_APP_ADMIN_PATH} render={(props) => {
            setRenderRoute(props.location.key);
            return (
              islogin
              ? <SelectLayout {...props} />
              : <Redirect to={{
                  pathname: process.env.REACT_APP_ADMIN_PATH + "/login",
                  state: { referrer: props.location }
                }} />
            );
          }} />
          <Redirect from="/" to={process.env.REACT_APP_ADMIN_PATH + "/school"} />
        </Switch>
      </BrowserRouter>
    </AuthenContext.Provider>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
