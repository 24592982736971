import React, { useState, useContext } from "react";

import AuthenContext from "../models/AuthenContext";

function Logout() {
  const {authen, setIsLogin} = useContext(AuthenContext);
  sessionStorage.removeItem("authen");
  setIsLogin(false);

  return null;
}

export default Logout;
