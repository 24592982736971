//import Dashboard from "views/Dashboard.js";
//import UserProfile from "views/UserProfile.js";
import Login from "views/Login";
import School from "views/School";
import Password from "views/Password";
import Logout from "views/Logout";

const adminRoutes = [
  /*{
    path: "/adminupbean/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/adminupbean/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },*/
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/login",
    name: "Login",
    icon: "nc-icon nc-circle-09",
    component: Login,
    layout: "/blank",
    redirect: true,
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/school",
    name: "หน้าแรก",
    icon: "fas fa-school",
    component: School,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/password",
    name: "เปลี่ยนรหัสผ่าน",
    icon: "fas fa-key",
    component: Password,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/logout",
    name: "ออกจากระบบ",
    icon: "fas fa-sign-out-alt",
    component: Logout,
    layout: "/admin",
  },
];

export default adminRoutes;
