import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";

function Page({ activePage, startPage, endPage, maxPage, onClick, className }) {
  const handleClick = (e, page) => {
    onClick(e, page);
  }

  let prevPage = activePage == 1 ? 1 : activePage - 1;
  let nextPage = activePage == maxPage ? maxPage : activePage + 1;

  return (
    (maxPage > 1 && startPage != null && endPage != null) && <Pagination className={className}>
      <Pagination.First onClick={(e) => handleClick(e, 1)} disabled={activePage == 1} />
      <Pagination.Prev onClick={(e) => handleClick(e, prevPage)} disabled={activePage == 1} />

      {[...Array(endPage - startPage + 1).keys()].map((value) => {
        return <Pagination.Item onClick={(e) => handleClick(e, value + startPage)} active={activePage == value + startPage} key={value}>{value + startPage}</Pagination.Item>
      })}

      <Pagination.Next onClick={(e) => handleClick(e, nextPage)} disabled={activePage == maxPage} />
      <Pagination.Last onClick={(e) => handleClick(e, maxPage)} disabled={activePage == maxPage} />
    </Pagination>
  );
}

export default Page;
