import React, { useState, useEffect } from "react";

const iconLoading = "fas fa-spin fa-spinner";

function ButtonLoading({ type, icon, className, loading, onClick, children }) {
  const [iconButton, setIconButton] = useState(icon);

  useEffect(() => {
    if(loading) {
      setIconButton(iconLoading);
    }
    else {
      setIconButton(icon);
    }
  }, [loading]);

  return (
    type == "link"
    ? <a href="#" className={className} onClick={onClick}><i className={iconButton}></i> {children}</a>
    : <button type={type} className={className} onClick={onClick}><i className={iconButton}></i> {children}</button>
  );
}

export default ButtonLoading;
