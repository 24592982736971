import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { WatDatePicker } from "thaidatepicker-react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";
import Page from "../components/Controls/Page";

function School(props) {
  const history = useHistory();

  const {authen, setIsLogin} = useContext(AuthenContext);

  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formTitle, setFormTitle] = useState("");

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [confirm, setConfirm] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {}
  });

  const [search, setSearch] = useState({
    "keyword": ""
  });

  const handleSearchInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

    setPages({
      ...pages,
      "activePage": 1
    });
  }

  const initForm = {
    "school_id": "",
    "school_name": "",
    "expire_date": "",
    "package_id": "",
    "contact_name": "",
    "tel": "",
    "school_user": "",
    "school_pass": "",
    "school_status": "1"
  };

  let activePage = new URLSearchParams(props.location.search).get("page");
  activePage = activePage === null ? 1 : parseInt(activePage);

  const [locationKeys, setLocationKeys] = useState([]);
  const [table, setTable] = useState([]);
  const [pages, setPages] = useState({
    "activePage": activePage
  });

  const handlePageChange = (e, page) => {
    history.push("?page=" + page);
  }

  const [form, setForm] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    loadTable();
  }, [search, pages.activePage]);

  useEffect(() => {
    setPages({
      ...pages,
      "activePage": activePage
    });

    return history.listen(location => {
      if(history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
  
      if(history.action === "POP") {
        if(locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const handleFormInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleExpireDate = (christDate, buddhistDate) => {
    console.log("christDate:", christDate, "buddhistDate:", buddhistDate);
    
    setForm({
      ...form,
      "expire_date": christDate
    });
  }

  const loadTable = () => {
    axios.post(process.env.REACT_APP_API_URL + "/admin/school.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "keyword": search.keyword,
      "page": pages.activePage
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setPages(page);
        setTable(data.map((row) => {
          return {...row, loadingEdit: false, loadingDelete: false};
        }));
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadPackages = () => {
    axios.post(process.env.REACT_APP_API_URL + "/admin/package.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setPackages(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadEdit = (index) => {
    let id = table[index].id;
    
    axios.post(process.env.REACT_APP_API_URL + "/admin/school.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "id": id
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm(data);
        setValidated(false);
        setFormTitle("แก้ไขโรงเรียน");
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      table[index].loadingEdit = false;
    });
  }

  const addData = (e) => {
    loadPackages();
    setForm(initForm);
    setValidated(false);
    setFormTitle("เพิ่มโรงเรียน");
    setShowForm(true);
  }

  const editData = (e, index) => {
    e.preventDefault();
    table[index].loadingEdit = true;
    loadPackages();
    loadEdit(index);
  }

  const deleteData = (e, index) => {
    e.preventDefault();
    table[index].loadingDelete = true;
    setConfirm({
      "show": true,
      "text": `ต้องการลบ "${table[index].school_name}" ใช่หรือไม่`,
      "confirmBtnText": "ลบ",
      "onConfirm": () => {
        let id = table[index].id;

        axios.post(process.env.REACT_APP_API_URL + "/admin/school.delete", {
          "api_key": process.env.REACT_APP_API_KEY,
          "token": authen.token,
          "id": id
        })
        .then((res) => {
          let { status, data } = res.data;
          if(status == 1) {
            loadTable();
          }
          else if(status == -1) {
            setIsLogin(false);
          }
          else {
            setAlert({
              "show": true,
              "text": res.data.msg
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          setAlert({
            "show": true,
            "text": process.env.REACT_APP_SERVER_NOT_CONNECT
          });
        })
        .finally(() => {
          table[index].loadingDelete = false;
        });

        setConfirm({"show": false});
      },
      "cancelBtnText": "ยกเลิก",
      "onCancel": () => {
        setConfirm({"show": false});
        table[index].loadingDelete = false;
      }
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/admin/school.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          loadTable();
          setShowForm(false);
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Form inline>
                      <Form.Group>
                        <Form.Label className="mr-sm-2">ค้นหา</Form.Label>
                        <Form.Control type="text" name="keyword" value={search.keyword} placeholder="ค้นหา" onChange={handleSearchInputChange}></Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md="6">
                    <Button type="button" variant="info" className="btn-fill float-right" onClick={addData}><i className="fas fa-plus"></i> เพิ่มโรงเรียน</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th width="80">ลำดับ</th>
                      <th width="120">วันที่สมัคร</th>
                      <th>โรงเรียน</th>
                      <th width="120">วันหมดอายุ</th>
                      <th width="120">เหลืออีก</th>
                      <th width="120">สถานะ</th>
                      <th width="120"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((row, index) => (
                      <tr key={index}>
                        <td className="text-center">{row.no}</td>
                        <td className="text-center">{row.created_at_f}</td>
                        <td>{row.school_name}</td>
                        <td className="text-center">{row.expire_date_f}</td>
                        <td className="text-center">{row.expire_day} วัน</td>
                        <td className="text-center">{row.school_status_text}</td>
                        <td className="text-center btn-manage-group">
                          <ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row.loadingEdit} onClick={e => editData(e, index)}>แก้ไข</ButtonLoading>
                          <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row.loadingDelete} onClick={e => deleteData(e, index)}>ลบ</ButtonLoading>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Page {...pages} onClick={handlePageChange} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        backdrop="static"
        show={showForm}
        onHide={() => setShowForm(false)}
      >
        <Form noValidate validated={validated} onSubmit={formSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>{formTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">โรงเรียน</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="school_name" value={form.school_name} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน โรงเรียน</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">วันหมดอายุ</Form.Label>
              <Col sm="4">
                <WatDatePicker
                  name="expire_date"
                  value={form.expire_date}
                  onChange={handleExpireDate}
                  dateFormat={"yyyy-MM-dd"}
                  displayFormat={"DD/MM/YYYY"}
                  clearable={false}
                  className={"form-control"}
                  required
                  requiredFeedback={<Form.Control.Feedback type="invalid">กรุณาป้อน วันหมดอายุ</Form.Control.Feedback>}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">แพคเกจ</Form.Label>
              <Col sm="10">
                <Form.Control as="select" name="package_id" value={form.package_id} onChange={handleFormInputChange} required>
                  <option value=""></option>
                  {packages.map(row => (
                    <option value={row.package_id} key={row.package_id}>{row.package_name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน แพคเกจ</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">ผู้ประสานงาน</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="contact_name" value={form.contact_name} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน ผู้ประสานงาน</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">เบอร์โทร</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="tel" value={form.tel} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน เบอร์โทร</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Username</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="school_user" value={form.school_user} onChange={handleFormInputChange} autoComplete="off" required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน Username</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Password</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="school_pass" value={form.school_pass} onChange={handleFormInputChange} autoComplete="off" required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน Password</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">สถานะ</Form.Label>
              <Col sm="10">
                <Form.Check type="radio" id="school_status_1" name="school_status" value="1" label="ใช้งาน" checked={form.school_status == "1"} onChange={handleFormInputChange} custom />
                <Form.Check type="radio" id="school_status_0" name="school_status" value="0" label="ระงับใช้งาน" checked={form.school_status == "0"} onChange={handleFormInputChange} custom />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-primary btn-fill mr-sm-2" loading={loadingSave}>บันทึก</ButtonLoading>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowForm(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirm.show && <SweetAlert
                        title={confirm.text}
                        btnSize="md"
                        onConfirm={confirm.onConfirm}
                        confirmBtnText={confirm.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirm.onCancel}
                        cancelBtnText={confirm.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default School;
